import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import resourcesToBackend from "i18next-resources-to-backend";
import Backend from "i18next-http-backend";
import axios from "axios";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

export const availableLanguages = ["en", "de", "es", "hu"];

export const cdn_base_url_promise = axios.get<string>(
    ((process.env.REACT_APP_MARKR_SERVER_URL as string) ?? "").concat(
        `/file/cdn_base_url`
    )
);

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //   .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // .use(
    //     resourcesToBackend(
    //         (language: string, namespace: string) =>
    //             import(`./locales/${language}/${namespace}.json`)
    //     )
    // )
    .use(Backend)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: async (lng: string, ns: string) => {
                return `${
                    (await cdn_base_url_promise).data
                }/locales/${lng}/${ns}.json`;
            },
            crossDomain: true,
            crossOriginIsolated: false,
        },
        fallbackLng: "en",
        supportedLngs: availableLanguages,
        preload: availableLanguages,
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })
    .then()
    .catch((e) => {
        console.log("Failed to init i18n!");
        console.log(e);
    });

export default i18n;
