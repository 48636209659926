import { createContext, useEffect, useState } from "react";
import { cdn_base_url_promise } from "@doar/shared/data/i18n";

export const CDNBaseURLContext = createContext<string>("");

export const CDNBaseURLProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [domainState, setDomain] = useState("");

    useEffect(() => {
        if (domainState != "") {
            return;
        }

        const runSetDomainState = async () => {
            setDomain((await cdn_base_url_promise).data);
        };

        runSetDomainState().catch((e) => {
            console.log("Failed to get cdn base url in provider!");
            console.log(e);
        });
    });

    return (
        <CDNBaseURLContext.Provider value={domainState}>
            {children}
        </CDNBaseURLContext.Provider>
    );
};
