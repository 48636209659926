import { StyledDropMenu } from "@doar/components/src/ui/dropdown/style";
import { SuggestionDropdownItem } from "@doar/components";
import { IRubricResult, SimpleResult } from "@doar/shared/types";
import React, { useEffect } from "react";
import { useClickOutside } from "@doar/shared/hooks";

interface SuggestionDropdownProps {
    x: number;
    y: number;
    width: number;
    height: number;
    items: SimpleResult[];
    rubric: IRubricResult;
    callback: (selectedSuggestion: SimpleResult, rubric: IRubricResult) => void;
    show: boolean;
    hideDropdown: () => void;
}

export const SuggestionDropdown = ({
    x,
    y,
    width,
    height,
    items,
    rubric,
    callback,
    show,
    hideDropdown,
}: SuggestionDropdownProps) => {
    const dropdownStyle: React.CSSProperties = {
        display: show ? "block" : "none",
        top: `${y + height}px`,
        left: `${x}px`,
        width: `${width}px`,
        userSelect: "none",
        padding: "0px",
        overflow: "hidden",
    };
    const dropdownRef = useClickOutside<HTMLDivElement>(hideDropdown);

    useEffect(() => {
        if (items) {
            dropdownRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
    }, [items, dropdownRef]);

    return (
        <StyledDropMenu
            key="suggestion_menu"
            style={dropdownStyle}
            ref={dropdownRef}
        >
            {items.map((s, i) => (
                <SuggestionDropdownItem
                    key={i}
                    onClick={() => callback(s, rubric)}
                >
                    <span
                        style={{
                            fontWeight: "bold",
                            color: "#000000",
                            textAlign: "right",
                            width: "1em",
                        }}
                    >
                        {s.score}
                    </span>
                    &nbsp;&nbsp;
                    {s.feedback.text.slice(0, 400)}
                </SuggestionDropdownItem>
            ))}
        </StyledDropMenu>
    );
};
