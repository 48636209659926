import React, { createContext, useState } from "react";
import axios from "axios";
import formurlencoded from "form-urlencoded";

import { TLms, TUser } from "@doar/shared/types";

const emptyUser = {
    jwt: "",
    roles: [],
    is_tutorial_finished: true,
} as TUser;

interface IErrorCallback {
    errorCallback?: (error: string) => void;
}

interface IUsernamePassword extends IErrorCallback {
    username: string;
    password: string;
}

interface ICanvasToken extends IErrorCallback {
    token: string;
    domain: string;
}

interface IToken extends IErrorCallback {
    token: string;
    lms: "canvas" | "moodle";
    domain: string;
}

interface IReturnValues {
    token_type: string;
    access_token: string;
}

export const UserContext = createContext<{
    user: TUser;
    loginWithPassword: ({ username, password }: IUsernamePassword) => void;
    loginWithLMSToken: ({ token, lms, domain }: IToken) => void;
    loginWithJWT: (jwt: string) => void;
    logout: () => void;
    autoLogin: (token?: string) => void;
    setUser: React.Dispatch<React.SetStateAction<TUser>>;
}>({
    user: emptyUser,
    loginWithPassword: async (_x: IUsernamePassword) => {},
    loginWithLMSToken: async (_x: IToken) => {},
    loginWithJWT: async (_jwt: string) => {},
    logout: () => {},
    autoLogin: (_token?: string) => {},
    setUser: () => {},
});

interface IProps {
    children: React.ReactNode;
}

const saveUserToLocalStorage = (user: TUser) => {
    localStorage.setItem("jwt", user.jwt);
    localStorage.setItem("token", (user.external_auth?.token as string) ?? "");
    localStorage.setItem(
        "domain",
        (user.external_auth?.domain as string) ?? ""
    );
    localStorage.setItem("userType", (user.user_type as string) ?? "markr");
};

const UserProvider = ({ children }: IProps) => {
    const [user, setUser] = useState<TUser>(emptyUser);

    const logout = () => {
        setUser(emptyUser);
        localStorage.removeItem("jwt");
        localStorage.removeItem("token");
        localStorage.removeItem("domain");
        localStorage.removeItem("userType");
    };

    const loginWithJWT = async (_jwt: string) => {
        await axios
            .get<TUser>(
                (
                    (process.env.REACT_APP_MARKR_SERVER_URL as string) ?? ""
                ).concat("/user/me"),
                {
                    headers: {
                        Authorization: "Bearer ".concat(_jwt),
                        Accept: "application/json",
                    },
                }
            )
            .then((dd) => {
                const u = dd.data;
                if (!u) {
                    return;
                }

                const newUser = {
                    ...u,
                    initials:
                        (u.name || "")
                            .split(" ")
                            .map((n: string) => n[0])
                            .join("") || "",
                    jwt: _jwt,
                };
                setUser(newUser);
                saveUserToLocalStorage(newUser);
                // toast.success(i18n.t("notification.successful_login"));
            })
            .catch((e) => e.response.status === 401 && logout());
    };

    const loginWithPassword = async ({
        username,
        password,
        errorCallback,
    }: IUsernamePassword) => {
        const results = await axios
            .post<IReturnValues>(
                (
                    (process.env.REACT_APP_MARKR_SERVER_URL as string) ?? ""
                ).concat("/user/token"),
                formurlencoded({ username, password }),
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .catch((e) =>
                errorCallback?.(
                    (e.response?.data?.detail as string) ?? "Unknown error"
                )
            )
            .then((d) => d?.data);

        if (results) {
            await loginWithJWT(results.access_token);
        }
    };

    const loginWithCanvasToken = async ({
        token,
        domain,
        errorCallback,
    }: ICanvasToken) => {
        await axios
            .get<TUser>(
                (
                    (process.env.REACT_APP_MARKR_SERVER_URL as string) ?? ""
                ).concat("/integrations/canvas/login/token"),

                {
                    params: {
                        token: token,
                        domain: domain,
                    },
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .catch((e) =>
                errorCallback?.(
                    (e.response?.data?.detail as string) ?? "Unknown error"
                )
            )
            .then((data) => {
                if (!data) {
                    return;
                }

                const newUser = {
                    ...data?.data,
                    initials:
                        (data?.data.name || "")
                            .split(" ")
                            .map((n: string) => n[0])
                            .join("")
                            .replace(/\W/g, "") || "",
                    external_auth: {
                        domain: domain,
                        token: token,
                    },
                };

                setUser(newUser);
                saveUserToLocalStorage(newUser);
            });
    };

    const loginWithMoodleToken = async ({
        token,
        domain,
        errorCallback,
    }: ICanvasToken) => {
        await axios
            .get<TUser>(
                (
                    (process.env.REACT_APP_MARKR_SERVER_URL as string) ?? ""
                ).concat("/integrations/moodle/login/token"),
                {
                    params: {
                        token: token,
                        domain: domain,
                    },
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .catch((e) => {
                console.log(e);
                errorCallback?.(
                    (e.response?.data.detail as string) ?? "Unknown error"
                );
            })
            .then((data) => {
                if (!data) {
                    return;
                }

                const newUser = {
                    ...data?.data,
                    initials:
                        (data?.data.name || "")
                            .split(" ")
                            .map((n: string) => n[0])
                            .join("")
                            .replace(/\W/g, "") || "",
                    external_auth: {
                        domain: domain,
                        token: token,
                    },
                };

                setUser(newUser);
                saveUserToLocalStorage(newUser);
            });
    };

    const loginWithLMSToken = async ({
        token,
        lms,
        domain,
        errorCallback,
    }: IToken) => {
        if (lms === "canvas") {
            await loginWithCanvasToken({ token, domain, errorCallback });
        }
        if (lms === "moodle") {
            await loginWithMoodleToken({ token, domain, errorCallback });
        }
    };

    const autoLogin = (token?: string) => {
        if (user.jwt) {
            return;
        }
        if (token) {
            loginWithJWT(token)
                .then(() => {})
                .catch(() => 1);

            return;
        }
        const _jwt = token || localStorage.getItem("jwt");
        if (localStorage.getItem("token")) {
            loginWithLMSToken({
                token: localStorage.getItem("token") as string,
                lms: localStorage.getItem("userType") as TLms,
                domain: localStorage.getItem("domain") as string,
            })
                .then(() => {})
                .catch(() => 1);
            return;
        }
        if (_jwt) {
            loginWithJWT(_jwt)
                .then(() => {})
                .catch(() => 1);
        }
    };

    return (
        <UserContext.Provider
            value={{
                user,
                loginWithPassword,
                loginWithLMSToken,
                loginWithJWT,
                logout,
                autoLogin,
                setUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
